import React, { Fragment } from "react";
import { Box, Flex, Text } from "rebass";
import Card from "./Card";

//Assets
//import img1 from "/static/models/textures/bose-cover-immersive-stories.jpg";
//import img2 from "../img/bose/card-image-bose-ds.png";

function BoseProjectCards() {
  return (
    <Flex
      flexWrap={["wrap", "wrap", "wrap","nowrap"]}
      variant="container"
      pt={[3, 4]}
      mb={[4, 5]}
      sx={{ gap: [2, 3] }}
      ml={["0", "0", "0", "0", "25ch"]}
    >
      <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 2, 1 / 3]} mb={[3]}>
        <Card
          sx={{
            backgroundColor: "boseProjectBgDs",
            color: "boseProjectTextDs",
            "& .label": {
            backgroundColor: "boseProjectTextDs",
            color:"boseProjectBgDs"
            },
            "& .label::after": {
            borderLeftColor:"boseProjectTextDs",
            }
          }}
          eyebrow={"Case study"}
          year={"2024"}
          title={"Solid Foundations"}
          page="/work/bose/bose-design-system/"
          info={
            <Fragment>
              <Text as="span">
                <strong>eCommerce, Website, Design System</strong>
              </Text>
            </Fragment>
          }
          //img={img2}
        />
      </Box>
      <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 2, 1 / 3]} mb={[3]}>
        <Card
          sx={{
            backgroundColor: "boseProjectBgImmersive",
            color: "boseProjectTextImmersive",
            "& .label": {
            backgroundColor: "boseProjectTextImmersive",
            color:"boseProjectBgImmersive"
            },
            "& .label::after": {
            borderLeftColor:"boseProjectTextImmersive",
            }
          }}
          eyebrow={"Case study"}
          year={"2024"}
          title={"Immersive Brand Stories"}
          page="/work/bose/bose-immersive-brand-stories/"
          info={
            <Fragment>
              <Text as="span">
                <strong>eCommerce, Website, UX Design</strong>
              </Text>
            </Fragment>
          }
          //img={img1}
        />
      </Box>
    </Flex>
  );
}

export default BoseProjectCards;
