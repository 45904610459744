import BoseProjectCards from "./BoseProjectCards";
import AccellProjectCards from "./AccellProjectCards";
import OberonProjectCards from "./OberonProjectCards";

export interface Company {
  title: string;
  location: string;
  tagline: string;
  discipline: string;
  id: string;
  page: string;
  year: string;
  label: string;
  companyProjects: React.FC | null;
  companyProjectNo: string;
}

export const companies: Company[] = [
  {
    title: "BOSE",
    location: "Purmerend, NL",
    tagline:
      "Sound is power. Sound has the power to transport us. Transform us. To make us feel alive.",
    discipline: "Audio Electronics, B2C",
    id: "100",
    page: "/work/bose/",
    year: "2022 - 2024",
    label: "",
    companyProjects: BoseProjectCards,
    companyProjectNo: "2",
  },
  {
    title: "OBERON",
    location: "Amsterdam, NL",
    tagline: "Complex IT made clear. Sustainable, on time and within budget.",
    discipline: "Digital Development Agency, Saas",
    id: "101",
    page: "/work/oberon/",
    year: "2021 - 2022",
    label: "",
    companyProjects: OberonProjectCards,
    companyProjectNo: "0",
  },
  {
    title: "ACCELL",
    location: "Amsterdam, NL",
    tagline: "Accell Group makes bicycles, bicycle parts and accessories.",
    discipline: "Transportation, B2B, B2C",
    id: "102",
    page: "/work/accell-group/",
    year: "2019 - 2021",
    label: "",
    companyProjects: AccellProjectCards,
    companyProjectNo: "3",
  },
];
