import React, { ReactElement } from "react";
import { Flex, Box, Heading, Text, Button } from "rebass";
import { SxStyleProp } from "theme-ui";
import Close from "../icons/Close";
import { ULink } from "../components/ULink";

interface IProps {
  eyebrow?: string | undefined;
  year?: string | undefined;
  title?: string | undefined;
  subheading?: string | undefined;
  desc?: ReactElement | null;
  info?: ReactElement | null;
  img?: string | undefined;
  to?: string | undefined;
  page?: string | undefined;
  sx?: SxStyleProp;
  modalLink?: {
    toggle: () => void;
    isShown?: boolean; // Optional, if you need to use the visibility state inside the Card
  };
  cardBg?: string | undefined;
  cardText?: string | undefined;
}

const Card = (props: IProps) => {
  return (
    <Box
      width={[1 / 1]}
      height={"100%"}
      p={props.img ? [0, 0, 0] : [4, 4, 5]}
      sx={{
        backgroundColor: props.cardBg ? props.cardBg : "backgroundElevation",
        color: props.cardText ? props.cardText : "text",
        borderRadius: "2.4rem",
        position: "relative",
        ...props.sx,
      }}
    >
      {/* Project Card */}
      {props.page ? (
        <ULink
          {...props}
          to={props.page!}
          activeClassName="active"
          sx={{
            display: "block",
            height: "100%",
          }}
        >
          <Flex
            flexWrap={["wrap", "nowrap"]}
            height={["auto", "100%"]}
            alignItems="center"
          >
            <Box
              alignSelf="stretch"
              width={
                props.img
                  ? [1 / 1, 1 / 1, 1 / 1, 3 / 5]
                  : [1 / 1, 1 / 1, 4 / 5, 3 / 5]
              }
              p={props.img ? ["2.4rem", 4, 5] : [0, 0, 0]}
            >
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                alignSelf="stretch"
                height="100%"
              >
                {props.eyebrow || props.year ? (
                  <Box 
                    sx={{ 
                      alignSelf: "stretch", 
                      flex: "1 0 0" 
                    }}
                  >
                    {props.eyebrow && (
                      <Text as="span" className="label" variant="projectLabel" mb={[2, 3, 4]}>
                        {props.eyebrow}
                      </Text>
                    )}
                    {props.year && (
                      <Text as="span" mb={[2, 3, 4]} ml={[3]}>
                        <strong>{props.year}</strong>
                      </Text>
                    )}
                  </Box>
                ) : null}

                {props.title ? (
                  <Box sx={{ alignSelf: "stretch", flex: "1 0 0" }}>
                    <Heading
                      as="h2"
                      variant="bigTitleXXS"
                      mb={[2, 3]}
                      mt={[1]}
                      sx={{ color: "inherit", maxWidth: props.img ? "none": "12ch" }}
                    >
                      {props.title}
                    </Heading>
                  </Box>
                ) : null}

                <Box>{props.info}</Box>

                {props.subheading ? (
                  <Box sx={{ alignSelf: "stretch", flex: "1 0 0" }}>
                    <Text as="p" variant="paragraphXL">
                      <strong>{props.subheading}</strong>
                    </Text>
                  </Box>
                ) : null}

                {props.desc ? <Box mb={[1, 2]}>{props.desc}</Box> : null}
              </Flex>
            </Box>

            {/* Image */}
            {props.img ? (
              <Box
                width={[3 / 5, 1 / 1, 1 / 1, 2 / 5]}
                p={["2.4rem",4]}
                mt={[-4, 0, 0, 0]}
                mx={["auto"]}
                ml={["auto","auto","auto",-4]}
                textAlign="center"
                sx={{
                  height: "100%",
                  overflow: "hidden",
                  "& > img, & > a img": {
                    objectFit: ["cover"],
                    height: "100%",
                    aspectRatio: ["3 / 4"],
                    borderRadius: "1.5rem",
                    transform:"rotate(8deg)",
                    transformOrigin: "50% 50%"
                  },
                }}
              >
                <img src={props.img} alt={props.title} />
              </Box>
            ) : null}
          </Flex>
        </ULink>
      ) : (
        <Flex
          flexWrap={["wrap", "nowrap"]}
          height={["auto", "100%"]}
          alignItems="center"
          onClick={props.modalLink?.toggle}
          sx={{ cursor: props.modalLink ? "pointer" : "text"}}
        >
          <Box
            alignSelf="stretch"
            width={[1/1]}
            p={props.img ? ["2.4rem", 4, 5] : [0, 0, 0]}
          >
            {props.modalLink ? (
              <Box
                sx={{
                  position: "absolute",
                  right: [3],
                  bottom: [3],
                  textAlign: "right",
                }}
              >
                <Button
                  sx={{ variant: "buttons.icon" }}
                  onClick={props.modalLink.toggle}
                  aria-label="Learn more"
                >
                  <Close />
                </Button>
              </Box>
            ) : null}

            <Flex
              flexDirection="column"
              alignItems="flex-start"
              alignSelf="stretch"
              height="100%"
            >
              {props.eyebrow ? (
                <Box>
                  <Text as="span" mt={[0, 2]} mb={[2, 3, 4]}>
                    <strong>{props.eyebrow}</strong>
                  </Text>
                </Box>
              ) : null}

              {props.title ? (
                <Box>
                  <Heading
                    as="h2"
                    variant="bigTitleXXS"
                    mb={[2, 3]}
                    sx={{ color: "inherit" }}
                  >
                    {props.title}
                  </Heading>
                </Box>
              ) : null}

              <Box mb={[2, 3, 4]}>{props.info}</Box>

              {props.subheading ? (
                <Box>
                  <Text as="p" variant="paragraphXL">
                    <strong>{props.subheading}</strong>
                  </Text>
                </Box>
              ) : null}

              {props.desc ? <Box mb={[1, 2]}>{props.desc}</Box> : null}

              {/* Image */}
              {props.img ? (
                <Box
                  width={[1 / 1]}
                  sx={{
                    height: "100%",
                    borderRadius: "1.5rem",
                    overflow: "hidden",
                    "& > img, & > a img": {
                      objectFit: "contain",
                      height: "100%",
                    },
                  }}
                >
                  <img src={props.img} alt={props.title} />
                </Box>
              ) : null}
            </Flex>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default Card;
